[dir=rtl] .MuiChip-label {
  font-family: Korek, serif !important;
}

.home .recharge {
  box-shadow: inset black 5px 15px 64px -58px;
}

.blue-program .banner-content.row {
  position: relative;
}
.blue-program .flex-grow-1 {
  position: absolute;
  width: 150px !important;
  right: 47px;
  bottom: -5px;
}

[dir=rtl] .blue-program .flex-grow-1 {
  right: auto;
  left: 0;
}

.bgBlueIMG {
  border-radius: 10px;
  overflow: hidden;
}

.btn.btn-light-warning {
  color: #ffcd00;
  background-color: #0057b7;
  border-color: transparent;
}

.btn.btn-light-warning.focus:not(.btn-text),
.btn.btn-light-warning:focus:not(.btn-text),
.btn.btn-light-warning:hover:not(.btn-text):not(:disabled):not(.disabled) {
  color: #ffcd00;
  background-color: #0057b7;
  border-color: transparent;
  opacity: 0.8;
}

.btn.btn-warning.focus:not(.btn-text),
.btn.btn-warning:focus:not(.btn-text),
.btn.btn-warning:hover:not(.btn-text):not(:disabled):not(.disabled) {
  color: #043659;
  background-color: #ffde59;
  border-color: #ffde59;
  opacity: 0.8;
}

.btn.btn-warning.disabled,
.btn.btn-warning:disabled {
  color: #043659;
  background-color: #ffde59;
  border-color: #ffde59;
  opacity: 0.58;
}

.btn.btn-outline-warning.focus:not(.btn-text),
.btn.btn-outline-warning:focus:not(.btn-text),
.btn.btn-outline-warning:hover:not(.btn-text):not(:disabled):not(.disabled) {
  color: #043659;
  background-color: #ffcd00;
  border-color: #ffcd00;
}

.spanDisableChild * {
  font-size: inherit !important;
  color: inherit !important;
  padding: inherit !important;
  margin: inherit !important;
}

@media (max-width: 991.98px) {
  .my-account .form-group .label-mobile {
    display: none;
  }

  .header-progress-bar {
    display: none !important;
  }

  .slick-arrow.slick-next {
    display: none !important;
  }

  .blue-home-serv-image {
    display: none;
  }

  .blue-program .under-slider .short-title {
    text-align: left;
  }

  [dir=rtl] .blue-program .under-slider .short-title {
    text-align: right;
  }

  .home .main-slider {
    max-height: inherit;
  }

  .mobile-a .dropdown-menu {
    max-height: 500px;
    overflow: scroll;
  }

  [dir=rtl] .home .korek-offers-new-home {
    background-position: left bottom, 0px 110px !important;
  }

  .blue-program .rewards .nav-item {
    width: auto;
  }
}
@media (max-width: 575.98px) {
  .my-account .form-group .label-mobile {
    display: none;
  }

  .blue-program .flex-grow-1 {
    right: -20px;
  }
  .blue-program .banner-blue {
    height: 240px;
  }
  .blue-program .title {
    font-size: 35px;
  }
}
@media (max-width: 991.98px) {
  .container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
    padding: 0 0 0 0;
  }
}
@media (min-width: 991.98px) and (max-width: 1200px) {
  .logo-brand {
    height: 40px;
  }

  .header {
    padding-left: 0;
    padding-right: 0;
  }

  .subheader {
    padding-left: 0;
    padding-right: 0;
  }
}
.error.error-3 .error-title {
  font-size: 8.7rem !important;
}

@media (min-width: 768px) {
  .error.error-3 .error-title {
    font-size: 15.7rem !important;
  }
}
//
// Content
//

	.footer {
		&.footer-business{
			background-color: rgb(0, 0, 0);
		}
		&.footer-mykorek{
			.MuiDivider-root {
			background-color: rgba(0, 81, 255, 0.2);
		}
			.footer-low{
				color: $dark-blue;
			}
			
			margin-left: 0px !important;
			margin-right: 0px !important;
		background-color: #FFFFFF;
		  ul.no-bullets li a{
			color: $dark-blue;
			font-weight: 100;
		  }
		.footer-per-bus{
			border: 2px solid $dark-blue;
			border-radius: 35px;
			width: max-content;
			color: $dark-blue;
			padding: 5px 7px;
		}
		h4{
			color: $dark-blue;
		}
		}
		background-color: $dark-blue;
		ul.no-bullets {
			list-style-type: none; /* Remove bullets */
			padding: 0; /* Remove padding */
			margin: 0; /* Remove margins */
		  }
		  ul.no-bullets li a{
			color: white;
			font-weight: 100;
		  }
		.footer-per-bus{
			border: 2px solid white;
			border-radius: 35px;
			width: max-content;
			color: white;
			padding: 5px 7px;
		}
	}
// Desktop Mode
@include media-breakpoint-up(lg) {
	.footer {
		
		.footer-per-bus{
			border: 2px solid white;
			border-radius: 35px;
			width: max-content;
			color: white;
			padding: 5px 7px;
		}
		ul.no-bullets {
			list-style-type: none; /* Remove bullets */
			padding: 0; /* Remove padding */
			margin: 0; /* Remove margins */
		  }
		  ul.no-bullets li a{
			color: white;
			font-weight: 100;
		  }
		padding: 75px 50px 0px !important;
		background-color: $dark-blue;
		// Fixed Mode
		.footer-fixed & {
			position: fixed;
			height: get($footer-config, fixed, height);
			z-index: get($footer-config, fixed, zindex);
			box-shadow: get($footer-config, fixed, shadow);
			bottom: 0;
			right: 0;
			left: 0;
		}

		.MuiDivider-root {
			background-color: rgb(255 255 255 / 20%);
		}
		.aside-enabled.footer-fixed & {
			left: get($aside-config, base, width);
		}

		// Aside Enabled, Aside Minimized, Aside Minimize Hover & Fixed Footer Modes
		.aside-enabled.aside-minimize.footer-fixed & {
			left: get($aside-config, base, minimized-width);
		}
	}
}

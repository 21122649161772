//
// Header
//

// Desktop Mode
@include media-breakpoint-up(lg) {
	.header {
		&.header-business .header-menu .menu-nav>.menu-item>.menu-link .menu-text {
				color: #ffffff;
				font-weight: 300 !important;
			}
		&.header-business{
			background-color: #000 !important;
		}
		&.header-business .header-menu .menu-nav>.menu-item.menu-item-active>.menu-link, .header .header-menu .menu-nav>.menu-item.menu-item-here>.menu-link{
			background-color: rgb(255 255 255 / 20%);
		}
		&.header-business .header-menu .menu-nav>.menu-item>.menu-link:hover{
			background-color: rgb(255 255 255 / 20%) !important;
			color: $yellow !important;
		}
		&.header-business .header-menu .menu-nav>.menu-item.menu-item-active>.menu-link .menu-arrow, &.header-business .header-menu .menu-nav>.menu-item.menu-item-active>.menu-link .menu-icon, &.header-business .header-menu .menu-nav>.menu-item.menu-item-active>.menu-link .menu-text, &.header-business .header-menu .menu-nav>.menu-item.menu-item-here>.menu-link .menu-arrow, &.header-business .header-menu .menu-nav>.menu-item.menu-item-here>.menu-link .menu-icon, &.header-business .header-menu .menu-nav>.menu-item.menu-item-here>.menu-link .menu-text			
		{
		color: $yellow !important;
		}

		&.header-business .header-menu .menu-nav>.menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)>.menu-link .menu-hor-arrow, &.header-business .header-menu .menu-nav>.menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)>.menu-link .menu-icon, &.header-business .header-menu .menu-nav>.menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)>.menu-link .menu-text, &.header-business .header-menu .menu-nav>.menu-item:hover:not(.menu-item-here):not(.menu-item-active)>.menu-link .menu-hor-arrow, &.header-business .header-menu .menu-nav>.menu-item:hover:not(.menu-item-here):not(.menu-item-active)>.menu-link .menu-icon, &.header-business .header-menu .menu-nav>.menu-item:hover:not(.menu-item-here):not(.menu-item-active)>.menu-link .menu-text
		{
		color: $yellow !important;
		}




		padding-left: 50px;
		padding-right: 50px;

		display: flex;
		justify-content: space-between;
		height: get($header-config, desktop, default, height);
		position: relative;
		z-index: 2;

		// Fixed Header Mode
		&.header-fixed {
			height: get($header-config, desktop, fixed, height);
			position: fixed;
			top: 0;
			right: 0;
			left: 0;
			z-index: get($header-config, desktop, fixed, zindex);
		}

		// Aside Enabled Mode
		.aside-enabled & {
			&.header-fixed {
				left: 0;
			}
		}

		// Fixed Header & Minimized Aside & Minimized Aside Hover Modes
		.header-fixed.aside-minimize & {
			left: get($aside-config, base, minimized-width);
		}

		// Fixed Header & Fixed Subheader Modes
		.header-fixed.subheader-fixed & {
			box-shadow: none !important;
		}
	}
}

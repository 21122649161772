//
// Subheader
//

.subheader {
	display: flex;
	align-items: center;
	.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover{
		color: $yellow;
	}

	// Subheader Separator
	.subheader-separator {
		display: block;
	    width: 12px;
	    height: 4px;
	    border-radius: 2px;

		&.subheader-separator-ver {
			width: 1px;
    		height: 22px;
		}
	}

	// Subheader Solid Mode
	&.subheader-solid {
		border-top: 1px solid get($subheader-config, default, border-color);
		background-color: $primary-blue;
	}
}

// Desktop Mode
@include media-breakpoint-up(lg) {
	.subheader-Scroll {
		// Fixed subheader mode
		.header-fixed.subheader-fixed & {
			top: 0 !important;
			height: 80px !important;
		}
		.scroll-login .dropdown-menu.show{
				transform: none !important;
				top: 62px !important;
				width: 400px !important;
		}
		.menu 
		{
			.dropdown-menu.show{
				top: 75px !important;
		}


		}
		a#offcanvasNavbarDropdown-expand-xl {
			height: 80px !important;
			padding-top: 1.9rem !important;
		}
	}
	.subheader-business{

		.header-fixed.subheader-fixed & {
			background-color: #000 !important;
		}
		.header-fixed.subheader-fixed .subheader{
			
			background-color: #000 !important;
		}
	}
	.subheader-business-fixed{
		.navbar-light .navbar-nav .nav-link {
			color: rgba(0,0,0,1) !important;
		}
		.header-fixed.subheader-fixed & {
			background-color: #E8E8E8 !important;
		}
	}
	.subheader {
			padding-left: 50px;
			padding-right: 50px;
		// Fixed subheader mode
		.header-fixed.subheader-fixed & {
			position: fixed;
			height: get($subheader-config, fixed, height);
			top: get($header-config, desktop, fixed, height);
			left: 0;
			right: 0;
			transition: top 0.3s ease;
			z-index: get($subheader-config, fixed, zindex);
			box-shadow: get($subheader-config, fixed, shadow);
			background-color: get($subheader-config, default, bg-color);
			border-top: 1px solid get($subheader-config, default, border-color);
			margin: 0;
		}

		// Fixed header, minimized header and fixed subheader
		.header-fixed.subheader-fixed.header-minimize:not(.aside-minimize-hover) & {
			top: get($header-config, desktop, fixed, height);
			transition: top 0.3s ease;
		}

		// Aside Enabled & Subheader Fixed Modes
		.aside-enabled.subheader-fixed & {
			left: 0;
		}
		.navbar-not .dropdown-menu.show{
				position: fixed;
				width: 100%;
				height: auto;
				top: 47px;
				border-radius: 0;
		}
		.navbar-scroll .dropdown-menu.show{
				position: fixed;
				width: 100%;
				height: auto;
				top: 75px;
				border-radius: 0;
		}

		.navbar-dark .navbar-nav .show > .nav-link, .navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .nav-link.active{
			color: $yellow;
		}
		// Fixed Subheader & Aside Minimize Hover Modes
		.subheader-fixed.aside-minimize & {
			left: get($aside-config, base, minimized-width);
		}
	}

    // Static Subheader
	.subheader-enabled:not(.subheader-fixed) {
		.content {
			padding-top: 0;
		}

		// Subheder Solid Mode
		.subheader.subheader-solid {
			margin-bottom: get($page-padding, desktop);
		}
	}
}

// Tablet & Mobile Modes
@include media-breakpoint-down(md) {
	// Mobile fixed subheader mode
	.subheader {
		.header-mobile-fixed.subheader-mobile-fixed & {
			position: fixed;
			height: get($subheader-config, fixed, height-mobile);
			top: get($header-config, tablet-and-mobile, fixed, height);
			left: 0;
			right: 0;
			transition: top 0.3s ease;
			z-index: get($subheader-config, fixed, zindex);
			box-shadow: get($subheader-config, fixed, shadow);
			background-color: get($subheader-config, default, bg-color);
			border-top: 1px solid get($subheader-config, default, border-color);
			margin: 0;
		}
	}

	// Content
	.content {
		.subheader-enabled:not(.subheader-mobile-fixed) & {
			padding-top: 0;
		}
	}

	// Subheder Solid Mode
	.subheader.subheader-solid {
		margin-bottom: get($page-padding, tablet-and-mobile);
	}
}
